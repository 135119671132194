main{
    padding: 9rem 9rem 0px;
}
.welcome-section, #Item  {
    background-color: white;
    border-radius: 5px;
    border: 1px solid gainsboro;
    height: 15vh;
    width: 100%;
    padding: 2rem;
}

.topic{
    margin-top: .7rem;
    display: flex;
    justify-content: space-between;
}
.lessons {
    margin-top: 2.7rem;

}
#Item {
    height: fit-content;
    padding: .9rem;
    padding-left: 2rem;
}

.circle, .line {
    height: 10px;
    width: 10px;
    background-color: black;
    border-radius: 50%;
}

.line {
    height: 8vh;
    width: 2px;
    border-radius: 0;

}

@media (max-width: 480px) {
    /* … */
    aside {
        position: fixed;
        background-color: #f0f2f5;
        height: 110px;
        width: 100%;
    }
    main{
        padding: 9rem 1rem 0px;
    }
    .welcome-section, #Item  {
        background-color: white;
        border-radius: 5px;
        border: 1px solid gainsboro;
        height: fit-content;
        width: 100%;
        padding: 1rem;
    }
    
    .topic{
        margin-top: .4rem;
        display: flex;
        justify-content: space-between;
    }
  }