.sidebar {
    height: 100vh;
}
.navigation-item {
    background-color: transparent;
}

.active {
    color: #3740ff;
    background: rgba(149, 182, 221, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
