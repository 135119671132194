.course-page {
    width: 100%;
    margin: 0;
    height: 100vh;
    overflow-x: hidden;
    background-color: white;
}

.logo {
    display: flex;
    gap: 1rem;
    align-items: baseline;
    height: 27px;
    font-size: 27px;
    text-transform: uppercase;
}

.logo:hover {
    cursor: pointer;
}

.footer {
    position: fixed;
    align-items: right;
    bottom: 0;
    width: 1pc;
}

.footer-actual {
    display: flex;
    justify-content: right;
    align-items: last baseline;
    background-color: blueviolet;
}

.lesson-nav {
    border-radius: 10px;
    padding-left: .7rem;
    display: flex;
    align-items: top;
    padding-top: 4px;
    height: 5vh;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.player {
    margin-bottom: 2rem;
    height: 70vh;
}

.player-inner {
    height: 100%;
    border-radius: 20px;
}

.player-inner iframe {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.notes {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.quiz {
    border: 1px solid gainsboro;
    border-radius: 10px;
}

.quiz>div {
    width: 100%;

}

.quiz-header {
    padding: 1rem;
    width: 100%;

}

.quiz-answers>div {
    width: 100%;

}

.quiz-answers label {
    width: 100%;
    margin-left: .1rem;
    padding: 8px 0px;
    border-top: 1px solid gainsboro;
}

.actions {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about-learning {
    display: flex;
    justify-content: space-between;
    align-items: left;

}

.about-learning div {
    display: flex;
    align-items: center;

}

@media (max-width: 480px) {
    .logo {
        display: flex;
        gap: 1rem;
        align-items: baseline;
        height: 16px;
        font-size: 16px;
        text-transform: uppercase;
    }

    .lesson-nav {
        position: fixed;
        z-index: 2;
        top: .5rem;
        left: 4rem;
        width: fit-content;
        padding-left: .7rem;
        padding-right: .7rem;
    }
    .cover {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        height: 7vh;
        width: 100%;
        background-color: white;

    }
    .player {
        height: 40vh;
    }

    .about-learning {
        flex-direction: column;
    }
}